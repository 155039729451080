/* stylelint-disable color-function-notation */

.date-popover {
    z-index: 999;
}

.read-more::before {
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    height: 150px;
    /* stylelint-disable-next-line alpha-value-notation */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    content: "";
}

.clickable {
    cursor: pointer;
}

.order-select .label {
    display: none !important;
}

@media (width >= 768px) {
    .u-pr80--gt768 {
        padding-right: 80px;
    }
}

/* Mobile tooltip on elements with title attribute */
@media (pointer: coarse), (hover: none) {
    .tooltip[title]:focus::after {
        content: attr(title);
        position: absolute;
        left: 50%;
        transform: translate(-50%, 28px);
        color: #000;
        background-color: #fff;
        border: 1px solid #bbb;
        border-radius: 8px;
        width: 250px;
        padding: 5px 8px;
        z-index: 999;
        font-size: 14px;
        font-weight: normal;
    }
}

/* InfoBox */

.info-box > .info {
    display: none;
    position: relative;
    padding: 8px 16px;
    margin-top: -12px;
    background-color: var(--troika-marble, #f6f8fb);
    border-radius: 4px;
}

.info-box > .info::before {
    content: "";
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    background-color: inherit;
    transform: rotate(45deg);
}

.info-box > .info.open {
    display: block;
}

.info-box > .info--left {
    margin-left: 16px;
}

.info-box > .info--left::before {
    display: block;
    top: 50%;
    left: -1px;
    margin: -8px;
}

/* Result page ads */

/* Fix native ads padding */

.ads__unit.ads__unit--native {
    padding-bottom: 0;
}

/* Fix native ads image padding */

.ads__unit > .ads__unit__img__ratio.img-format--ratio3by2 {
    padding-top: 0;
}

.ads__unit__link {
    color: var(--f-gray-700, #404040);
    font-size: 16px;
}

@media (width >= 768px) {
    .ads__unit--fullwidth {
        /* banner spanning two columns on desktop */
        grid-column: span 2 / span 2;
    }
}

/* Overriding Troika's z-index due to incompatibility with Fabric's z-index system */

.lightbox {
    z-index: 30 !important;
}

.mini-map {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
}

.mini-map .button {
    margin-top: -40px;
    margin-right: 7px;
}

.mini-map a::before {
    content: "";
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
}

.mini-map:focus-within a,
.mini-map a:focus {
    outline: 2px solid var(--f-blue-600, #0063fb) !important;
}

.remove-scroll-wheel::-webkit-outer-spin-button,
.remove-scroll-wheel::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.remove-scroll-wheel[type="number"] {
    appearance: textfield;
}

input:read-only:focus {
    box-shadow: none !important;
}

input:read-only::selection {
    background: transparent;
}

.in-progress {
    background-image: repeating-linear-gradient(-45deg, transparent, transparent 1rem, #ccc 1rem, #ccc 2rem) !important;
    background-color: #aaa !important;
    background-size: 200% 200%;
    animation: barberpole 10s linear infinite;
}

@keyframes barberpole {
    100% {
        background-position: 100% 100%;
    }
}
