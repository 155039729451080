.map-result-list {
    max-width: 800px;
}

@media (width <= 1199px) {
    .map-result-list {
        width: min(50%, calc(100% - 450px));
    }
}

@media (width >= 1200px) {
    .map-result-list {
        width: 40%;
        min-width: 600px;
    }
}

.result-list-map finn-map {
    height: 100%;
}

.map-top-bar {
    position: sticky;
    top: 50px;
    width: 100%;
    border-bottom: 1px solid #dfe4e8;
    background-color: #fff;
    z-index: 2;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: -16px;
}

.result-list-map {
    position: fixed;
    z-index: 2;
    background-color: #b8dcfc;
    border-left: 1px solid #dfe4e8;
}

.map-top-bar > .top-bar-button.top-bar-button,
.topbar-level2 > .top-bar-button.top-bar-button {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (width >= 768px) {
    .result-list-map {
        top: 50px;
        height: calc(100% - 50px);
    }
}

@media (width >= 768px) and (width <= 1199px) {
    .result-list-map {
        left: min(50%, calc(100% - 450px));
        width: max(50%, 450px);
    }
}

@media (width >= 1200px) and (width <= 1499px) {
    .result-list-map {
        left: max(40%, 600px);
        width: min(60%, calc(100% - 600px));
    }
}

@media (width >= 1500px) {
    .result-list-map {
        left: min(40%, 800px);
        width: max(60%, calc(100% - 800px));
    }
}

@media (width <= 767px) {
    .result-list-map {
        top: 100px;
        height: calc(100% - 100px);
        left: 0;
        width: 100%;
    }
}

/* ResultFilterMap */
@media (width <= 767px) {
    .filters-overlay-map {
        position: fixed;
        z-index: 2;
        inset: 97px 0 0;
        display: none;
        background-color: rgb(255 255 255 / 95%);
        transform: translateY(110%);
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }

    .filters-overlay-map.active {
        display: block;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 16px !important;
        transform: translateY(0);
        transform: translate3d(0, 0, 0);
        transition: transform 200ms;
    }
}

@media (width >= 768px) {
    .filters-overlay-map.map-view {
        position: fixed;
        z-index: 3;
        bottom: 0;
        border-left: 1px solid #dfe4e8;
        border-radius: 0 8px 8px 0;
        padding: 8px;
        display: none;
        background-color: #fff;
        transform: translateX(-100%);
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }

    .filters-overlay-map.map-view.active {
        display: block;
        overflow-y: scroll;
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
        transition: transform 200ms;
    }
}

@media (width >= 768px) and (width <= 1499px) {
    .filters-overlay-map.map-view {
        top: 97px;
        left: 0;
    }
}

@media (width >= 768px) and (width <= 1199px) {
    .filters-overlay-map.map-view {
        width: min(50%, calc(100% - 450px));
    }
}

@media (width >= 1200px) and (width <= 1499px) {
    .filters-overlay-map.map-view {
        width: max(40%, 600px);
    }
}

@media (width >= 1500px) {
    .filters-overlay-map.map-view {
        top: 50px;
        left: min(40%, 800px);
        width: 350px;
        box-shadow: rgb(0 0 0 / 15%) 0 5px 15px 0;
    }
}

/* HotelMapCard */

.two-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.card {
    position: relative;
    z-index: 1;
    border: 0;
    border-radius: 8px;
    background-color: var(--f-white, #fff);
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgb(71 68 69 / 24%);
    margin: 24px 0;
    width: 100%;
}

.mapboxgl-ctrl.card {
    margin: 0 0 20px;
    width: 450px;
    max-width: calc(100% - 40px);
}

.mapboxgl-ctrl.card:hover .map-card-name {
    text-decoration: underline;
}

.mapboxgl-ctrl.card:hover {
    box-shadow: 0 0 0 0, 0 4px 16px rgb(71 68 69 / 16%);
}

.map-card-image::after {
    padding-bottom: 125px;
}

.map-card-details {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.map-card-price {
    width: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

@media (width >= 375px) {
    .map-card-image {
        width: 100px;
        border-radius: 4px;
    }

    .map-card-details {
        width: calc(100% - 150px);
    }
}

@media (width <= 374px) {
    .map-card-details {
        width: calc(100% - 110px);
    }

    .map-card-image {
        display: none;
    }
}

.result-list-item-link {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    place-content: stretch flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
    color: var(--troika-licorice, #474445) !important;
    text-decoration: none;
}

.result-list-item-link:hover,
.result-list-item-link:active,
.result-list-item-link:focus {
    text-decoration: none;
}

.hotel-map-card-close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 0 8px 0 0;
    background: none;
}

.hotel-map-card-close-button:focus {
    border: 1px solid #06befb;
}
