.profile-identity-avatar {
    display: block; /* Needed to set width for ie10 */
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.profile-identity-avatar_img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}
