.feedback button:hover,
.feedback button:active {
    background: none;
}

.feedback button:focus,
.feedback button:not([disabled]):focus {
    box-shadow: none;
    background: none;
}

.feedback .feedback-emoji_rest path {
    fill: #dfe4e8;
}

.feedback .feedback-emoji_active path {
    fill: #0063fb;
}

.feedback .feedback-header__control_back {
    fill: #06befb;
}

.feedback .feedback-text {
    border-color: #dfe4e8;
}

.feedback .feedback-text:focus,
.feedback .feedback-text:active {
    border-color: #005dfb;
}

.feedback .feedback-tooltip__control path {
    fill: #767676;
}

.feedback .feedback-step__answer:hover path {
    fill: #06befb;
}

.feedback .tooltip-icon .feedback-svg__path {
    fill: #06befb;
}

.feedback .feedback-button {
    border-radius: 2px;
    font-size: 16px;
    line-height: 1.25;
    padding: 6px 10px;
}

.feedback .feedback-button_primary {
    border-color: #0063fb;
    background-color: #0063fb;
    color: #fff;
}

.feedback .feedback-button_primary:active,
.feedback .feedback-button_primary:hover {
    background-color: #004fc8;
    box-shadow: none;
    color: #fff;
}

.feedback .feedback-button_secondary {
    box-shadow: 0 0 0 1px #06befb;
    background: #fff;
    color: #0063fb;
}

.feedback .feedback-button_secondary:hover {
    box-shadow: 0 0 0 1px #767676;
    background: #fff;
    color: #0063fb;
}

.feedback .feedback-button_disabled,
.feedback .feedback-button_disabled:hover {
    box-shadow: 0 0 0 1px #f1f4f6;
    background: #f1f4f6;
    color: #767676;
    cursor: default;
}

.feedback .feedback-breadcrumb {
    padding: 0 10px;
}

.feedback .feedback-breadcrumb__step--visited {
    background-color: #b6f0ff;
}

.feedback .feedback-breadcrumb__step--visited:hover {
    background-color: #0063fb;
}

.feedback .feedback-breadcrumb__step--active {
    background-color: #0063fb;
}

.feedback .feedback-emoji_active .feedback-svg__path {
    fill: #0063fb;
}

.feedback .feedback-step__answer:hover .feedback-svg__path {
    fill: #0063fb;
}

.feedback .feedback-step__scale-point:hover .feedback-svg__path {
    fill: #06befb;
}

.feedback .feedback-step__answer .feedback-emoji_active .feedback-svg__path {
    fill: #0063fb;
}

.feedback .feedback-stepper__step_complete {
    background-color: #b6f0ff;
}

.feedback .feedback-stepper__step_complete:hover {
    background-color: #0063fb;
}

.feedback .feedback-stepper__step_active {
    background-color: #0063fb;
}

.feedback-header,
.feedback-step__text,
.feedback-step__buttongroup,
.feedback-step__title,
.feedback-tooltip,
.feedback-step__wrapper {
    padding: 10px;
}

.feedback .feedback-header__control .feedback-svg,
.feedback .feedback-tooltip__control {
    margin: 10px;
}

.feedback .feedback-breadcrumb_lines + .feedback-form {
    top: 52px;
}

@keyframes bubble-up {
    0% {
        transform: scale(0);
        background-color: #005dfb;
        border-radius: 50%;
    }

    100% {
        transform: scale(1);
        background-color: #06befb;
        border-radius: 0;
    }
}

.feedback .feedback-confirmation .feedback-confirmation_visible {
    animation-name: @include bubble-up();
}

@media screen and (width >= 400px) {
    .feedback .feedback-breadcrumb {
        padding: 0 20px;
    }

    .feedback .feedback-header,
    .feedback .feedback-step__text,
    .feedback .feedback-step__buttongroup,
    .feedback .feedback-step__title,
    .feedback .feedback-tooltip,
    .feedback .feedback-step__wrapper {
        padding: 20px;
    }

    .feedback .feedback-header__control .feedback-svg,
    .feedback .feedback-tooltip__control {
        margin: 20px;
    }

    .feedback .feedback-breadcrumb_lines + .feedback-form {
        top: 72px;
    }
}

.feedback .makeitfit {
    position: absolute;

    /* 65px is the size of the header + bottom margin */
    top: 65px;
    width: 100%;
    height: calc(100% - 65px);
}

.feedback .widget {
    border-radius: 2px;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
    height: 100%;
}

.feedback .profile {
    display: none;
    border-radius: 2px;
    margin: 0 auto 10px;
    height: 80px;
    background-color: #fff;
}

@media screen and (width >= 420px) {
    .feedback .widget {
        /* 90 is the height of profile + bottom margin */
        height: calc(100% - 90px);
    }

    .feedback .profile {
        display: block;
    }
}

@media screen and (width >= 400px) and (height >= 500px) {
    .feedback .widget {
        height: 480px;
    }

    .feedback .makeitfit {
        position: static;
    }
}

.objectRatingWidgetDesktop .scc_tr-item-reputation {
    padding-bottom: 32px;
}

.objectRatingWidgetDesktop .scc_tr-item-reputation_overall {
    margin-bottom: 8px;
}

.objectRatingWidgetDesktop .scc_tr-item-reputation_aspect {
    margin-top: 8px;
    width: 100% !important;
}

.objectRatingWidgetDesktop .scc_tr-item-reputation_bar {
    margin-top: 4px;
}
